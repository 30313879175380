<template>
  <transition name="fade-fast">
    <div v-show="show" class="app-popup-container">
      <div class="app-popup-mask" @click="close()"></div>
      <div class="select_client">
        <div class="select_client__top">
          <label class="select_client__title">Accede a {{title}}</label>
          <span 
            class="select_client__close material-symbols-outlined" 
            @click="close()"
          >
            close
          </span>
        </div>
        <div class="select_client__content">
          <LoadingInline v-if="loading" />
          <div v-else-if="clients.length === 0" class="select_client__row">
            <div class="select_client__row__data--error">
              <span class="error material-symbols-outlined">
                error
              </span>
              <p>Tu mail no está asociado a ninguna cuenta o dirección</p>
            </div>
          </div>
          <div v-else class="select_client__row" v-for="(client, index) in clients" :key="client.soldTo" @click="clientSoldTo = client.soldTo">
            <div class="select_client__row__data">
              <div 
                class="img" 
                :style="{
                  backgroundColor: getAvatarColor(index).background,
                  color: getAvatarColor(index).text
                }"
              >
                <span>{{ client.razonSocial ? client.razonSocial.charAt(0).toUpperCase() : "-"}}</span>
              </div>
              <div class="name-container">
                <p class="name">{{ client.razonSocial ?? "-" }}</p>
                <p class="email">{{ client.email }}</p>
              </div>
            </div>
            <label class="select_client__checkbox">
              <input
                type="radio"
                :value="client.soldTo"
                v-model="clientSoldTo"
              />
              <span class="checkbox">
                <span v-if="clientSoldTo === client.soldTo" class="material-symbols-outlined">
                  check
                </span>
              </span>
            </label>
          </div>
        </div>
        <div class="select_client__footer">
          <button class="btn btn__cancelar" @click="close()">Cancelar</button>
          <button class="btn"
            :disabled="clientSoldTo == null"
            :style="{ opacity: clientSoldTo == null ? 0.5 : 1 }"
            @click="enter()"
          >
            Ingresar
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import helpers from '@/mixins/helpers';
import LoadingInline from "@/components/LoadingInline.vue";

export default {
  name: "select_clientpopup",
  components: { LoadingInline },
  props: {
    title: String,
    callback: Function,
    modulo: String
  },
  data() {
    return {
      // data
      clients: [],
      // ui
      show: false,
      loading: false,
      errorSoldto: false,
      clientSoldTo: null,
    };
  },
  mixins: [helpers],
  created() {},
  mounted() {
  },
  computed: {},
  methods: {
    // data
    async getClients(){
      this.loading = true;
      try {
        const response = await this.$axios.get(this.$localurl + "/api/clients/related")
        this.clients = response.data
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    // ui
    async open(){
      if(this.clients.length == 0){
        await this.getClients();
      }
      if(this.clients.length === 1){
        this.clientSoldTo = this.clients[0].soldTo
        this.enter()
      }else{
        this.show = true
      }
    },
    close() {
      this.show = false;
      this.titulo = "";
      this.errorSoldto = false;
    },
    enter() {
      const that = this;
      if(this.clientSoldTo == null || this.clientSoldTo == ""){
        this.errorSoldto = true;
        return;
      }
      this.errorSoldto = false;
      this.$axios
        .get(this.$localurl + "/api/usuarios/chequearSoldtoPara/"+ this.modulo +"/" + this.clientSoldTo)
        .then(function () {
          localStorage.setItem("soldTo", that.clientSoldTo);
          that.close();
          that.callback();
        })
        .catch(function () {
          that.errorSoldto = true;
        });
    },
  },
};
</script>

<style scoped src="../assets/css/components/selectclientpopup.css"></style>