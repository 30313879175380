<template>
  <div
    :class="{
      'novedad-previa': !destacar,
      'novedad-previa-destacada': destacar,
      'novedad-previa-destacada--has-image': imagen != null && destacar,
    }"
  >
    <div
      :class="{
        'novedad-previa__imagen-container': !destacar,
        'novedad-previa-destacada__imagen-container': destacar,
      }"
    >
      <img
        v-show="imagen != null"
        :class="{
          'novedad-previa__imagen-img': !destacar,
          'novedad-previa-destacada__imagen': destacar,
        }"
        :src="'data:image/jpeg;base64,' + imagen"
      />
      <span
        v-show="imagen == null && !destacar"
        class="material-symbols-outlined novedad-previa__imagen-icon"
      >
        add_photo_alternate
      </span>
    </div>
    <label
      :class="{
        'novedad-previa__titulo': !destacar,
        'novedad-previa-destacada__titulo': destacar,
        'novedad-previa-destacada__titulo--has-image':
          imagen != null && destacar,
      }"
      >{{ titulo != "" ? titulo : "Título" }}</label
    >
    <label
      :class="{
        'novedad-previa__descripcion': !destacar,
        'novedad-previa-destacada__descripcion': destacar,
        'novedad-previa-destacada__descripcion--has-image':
          imagen != null && destacar,
      }"
      >{{ descripcion != "" ? descripcion : "Descripción.." }}</label
    >
    <div :class="{ 'novedad-leer-mas': !destacar }">
      <label v-if="(titulo == '' || descripcion == '') && !destacar"
        >Ver mas</label
      >
      <label class="novedad-previa-destacada__ver-mas" v-if="destacar"
        >Ver mas</label
      >
      <div v-if="titulo != '' && descripcion != '' && !destacar">
        <button>Material</button>
        <button>Ver video</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    destacar: {
      type: Boolean,
      default: false,
    },
    titulo: {
      type: String,
      default: "",
    },
    descripcion: {
      type: String,
      default: "",
    },
    imagen: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped src="../../assets/css/components/novedadpopup.css"></style>
