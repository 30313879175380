<template>
  <div class="links">
    <div class="links__inputs" v-for="(link, index) in links" :key="index">
      <p class="links__input">
        <label for="name">Nombre</label>
        <input
          id="name"
          type="text"
          placeholder="Nombre"
          :value="link.name"
          @input="updateLink(index, 'name', $event.target.value)"
          :class="{ 'error-input': link.error }"
        />
      </p>

      <p class="links__input">
        <label for="link">Enlace</label>
        <input
          id="link"
          type="text"
          placeholder="Pegar link de enlace acá"
          :value="link.url"
          @input="updateLink(index, 'url', $event.target.value)"
          :class="{ 'error-input': link.error }"
        />
      </p>
    </div>
    <div class="link_button">
      <button class="link_button__text" @click="addNewLink">
        + Agregar otro
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {};
  },
  methods: {
    addNewLink() {
      this.$emit("addLink", { name: "", url: "" });
    },
    updateLink(index, key, value) {
      const updatedLink = { ...this.links[index], [key]: value };
      this.$emit("updateLink", index, updatedLink);
    },
  },
};
</script>

<style scoped src="../assets/css/components/novedadpopup.css"></style>