var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'novedad-previa': !_vm.destacar,
    'novedad-previa-destacada': _vm.destacar,
    'novedad-previa-destacada--has-image': _vm.imagen != null && _vm.destacar,
  }},[_c('div',{class:{
      'novedad-previa__imagen-container': !_vm.destacar,
      'novedad-previa-destacada__imagen-container': _vm.destacar,
    }},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.imagen != null),expression:"imagen != null"}],class:{
        'novedad-previa__imagen-img': !_vm.destacar,
        'novedad-previa-destacada__imagen': _vm.destacar,
      },attrs:{"src":'data:image/jpeg;base64,' + _vm.imagen}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.imagen == null && !_vm.destacar),expression:"imagen == null && !destacar"}],staticClass:"material-symbols-outlined novedad-previa__imagen-icon"},[_vm._v(" add_photo_alternate ")])]),_c('label',{class:{
      'novedad-previa__titulo': !_vm.destacar,
      'novedad-previa-destacada__titulo': _vm.destacar,
      'novedad-previa-destacada__titulo--has-image':
        _vm.imagen != null && _vm.destacar,
    }},[_vm._v(_vm._s(_vm.titulo != "" ? _vm.titulo : "Título"))]),_c('label',{class:{
      'novedad-previa__descripcion': !_vm.destacar,
      'novedad-previa-destacada__descripcion': _vm.destacar,
      'novedad-previa-destacada__descripcion--has-image':
        _vm.imagen != null && _vm.destacar,
    }},[_vm._v(_vm._s(_vm.descripcion != "" ? _vm.descripcion : "Descripción.."))]),_c('div',{class:{ 'novedad-leer-mas': !_vm.destacar }},[((_vm.titulo == '' || _vm.descripcion == '') && !_vm.destacar)?_c('label',[_vm._v("Ver mas")]):_vm._e(),(_vm.destacar)?_c('label',{staticClass:"novedad-previa-destacada__ver-mas"},[_vm._v("Ver mas")]):_vm._e(),(_vm.titulo != '' && _vm.descripcion != '' && !_vm.destacar)?_c('div',[_c('button',[_vm._v("Material")]),_c('button',[_vm._v("Ver video")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }