import colorMixin from "./colorMixin";

export default {
  // importo los otros mixins
  mixins: [colorMixin],
  methods: {
    goToModule(module) {
      this.postMetric(module);
      let token = localStorage.getItem("token-landing");
      let ruta = "";
      switch (module) {
        case "mialta":
          ruta = this.$mialta_url + "/landing/" + token;
          break;
        case "cyo":
          token = localStorage.getItem("token");
          ruta = this.$cyo_url + "/landing/" + token;
          break;
        case "mispedidos":
          ruta = this.$mispedidos_url + "/landing?auth=" + token;
          break;
        case "cc":
          token = localStorage.getItem("token");
          ruta = this.$cc_url + "/landing/" + token;
          break;
        case "pog":
          token = localStorage.getItem("token");
          ruta = this.$pog_url + "/landing/" + token;
          break;
        case "simulador":
          ruta = this.$dev
            ? "https://dev.micuenta.basf.com.ar/simulador"
            : "https://micuenta.agro.basf.com/simulador";
          if (this.$usuario.email.toLowerCase().includes("basf.com")) {
            ruta =
              ruta +
              "/plancomercial/planificacion?auth=" +
              token +
              "&admin=true";
          } else ruta = ruta + "/landing?auth=" + token;
          break;
        case "documentos":
          if (this.$dev) {
            ruta =
              "https://dev.micuenta.basf.com.ar/misdocumentos/token/" + token;
          } else {
            ruta =
              "https://micuenta.agro.basf.com/misdocumentos/token/" + token;
          }
          break;
        case "mitienda":
          window
            .open(
              "https://mitiendabasf.com.ar?token=" + this.miTiendaToken,
              "_blank"
            )
            .focus();
          break;
        case "weboficial":
          window
            .open("https://agriculture.basf.com/ar/es.html", "_blank")
            .focus();
          break;
        default:
          break;
      }
      if (ruta) {
        window.location.href = ruta;
      }
    },
    postMetric(modulo) {
      this.$axios
        .post(this.$localurl + "/api/metrica/" + modulo)
        .then(function(response) {
          console.log(response);
        })
        .catch(function(response) {
          console.log(response);
        });
    },
  }
};