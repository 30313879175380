<template>
  <div
    v-if="show"
    class="delete-novedad-modal__background"
    @click="closeModal()"
  >
    <div class="delete-novedad-modal__container" @click.stop>
      <div class="delete-novedad-modal__header">
        <p>Eliminar</p>
        <span
          class="material-symbols-outlined delete-novedad-modal__close-icon"
          @click="closeModal()"
        >
          close
        </span>
      </div>
      <p>¿Estás seguro de eliminar la publicación ?</p>
      <div class="delete-novedad-modal__buttons">
        <button class="delete-novedad-modal__cancelar" @click="closeModal()">
          Cancelar
        </button>
        <button class="delete-novedad-modal__eliminar" @click="deleteElement()">
          Eliminar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    deleteElement() {
      this.$emit("delete");
    },
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped src="../assets/css/components/novedad.css"></style>
