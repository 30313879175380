<template>
  <div v-infocus="'showElement'" class="hidden hidden-down novedad-container">
    <button
      v-if="getRolUsuario()"
      class="novedad__action-button novedad__delete-button"
      @click.stop="openDeleteModal()"
    >
      <span class="material-symbols-outlined">
        delete
      </span>
    </button>
    <img
      class="academy-image"
      v-bind:src="creadorImage()"
      alt="course thumbnail"
    />
    <div class="novedad-info">
      <h2>{{ novedad.titulo }}</h2>
      <p>{{ novedad.descripcion }}</p>
      <span @click="openModalLinks(novedad.links)" style="cursor: pointer"
        >Leer mas</span
      >
    </div>

    <!-- Delete modal -->
    <DeleteModal
      :show="deleteModalIsOpen"
      @delete="deleteNovedad"
      @close="closeDeleteModal"
    ></DeleteModal>
    <LinkModal :show="show" :links="links" @close="closeModal"></LinkModal>
  </div>
</template>
<script>
import LinkModal from "../LinkModal.vue";
import DeleteModal from "../DeleteModal.vue";

export default {
  components: {
    LinkModal,
    DeleteModal
  },
  props: {
    novedad: Object,
  },
  data() {
    return {
      deleteModalIsOpen: false,
      show: false,
      links: [],
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      this.$emit("link-open", false);
    },
    openModalLinks(links) {
      this.show = false;
      if (links.length == 1) {
        window.open(links[0].url, "_blank", "noopener");
      } else {
        this.links = links;
        this.show = true;
        this.$emit("link-open", true);
      }
    },
    creadorImage() {
      return !this.anuncio?.creador?.imagen
        ? this.$localurl + "/" + this.novedad.imagen
        : require("../../assets/img/default.png");
    },
    openDeleteModal() {
      this.deleteModalIsOpen = true;
    },
    closeDeleteModal() {
      this.deleteModalIsOpen = false;
    },
    deleteNovedad() {
      const that = this;
      this.$axios
        .delete(this.$localurl + "/api/novedad/" + this.novedad.id)
        .then(function () {
          that.$eventHub.$emit("novedad-nuevo");
        })
        .catch(function (response) {
          console.log(response);
        });

      this.closeDeleteModal();
    },
    getRolUsuario() {
      if (
        (this.$usuario.rolClientes != null &&
          this.$usuario.rolClientes.detalle == "Performance") ||
        (this.$usuario.rolCYO != null &&
          this.$usuario.rolCYO.detalle == "Performance") ||
        (this.$usuario.rolPOG != null &&
          this.$usuario.rolPOG.detalle == "Performance") ||
        (this.$usuario.rolCC != null &&
          this.$usuario.rolCC.detalle == "Performance") ||
        this.$usuario.email == "ana.spioussas@basf.com" ||
        this.$usuario.email == "maria-ana.laguzzi@basf.com" ||
        this.$usuario.email == "gustavo.saldania@basf.com" ||
        this.$usuario.email == "jeronimo.carmody-fernandez@basf.com"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped src="../../assets/css/components/novedad.css"></style>
