import { render, staticRenderFns } from "./DeleteModal.vue?vue&type=template&id=183c0818&scoped=true"
import script from "./DeleteModal.vue?vue&type=script&lang=js"
export * from "./DeleteModal.vue?vue&type=script&lang=js"
import style0 from "../assets/css/components/novedad.css?vue&type=style&index=0&id=183c0818&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "183c0818",
  null
  
)

export default component.exports